import isEmpty from 'lodash/isEmpty'

import { isNumber } from '@core/helpers'

export default ({
  src,
  height,
  width,
  srcMobile = '',
  alt = '',
  heightMobile = undefined,
  widthMobile = undefined,
}) => {
  const widthMobileValid =
    typeof widthMobile === 'number' || typeof widthMobile === 'undefined'
  const heightMobileValid =
    typeof heightMobile === 'number' || typeof heightMobile === 'undefined'

  return (
    !isEmpty(src) &&
    isNumber(width) &&
    isNumber(height) &&
    typeof src === 'string' &&
    typeof srcMobile === 'string' &&
    typeof alt === 'string' &&
    widthMobileValid &&
    heightMobileValid
  )
}
