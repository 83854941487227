
import { RevCardGrid } from '@ds/components/CardGrid'
import { RevCategoryCard } from '@ds/components/CategoryCard'
import isEmpty from 'lodash/isEmpty'

import { trackClick } from '@tracking/events'

import BlockTitle from '../private/BlockTitle'

import TempStaticCategory from './TempStaticCategory.vue'

export default {
  components: {
    BlockTitle,
    RevCategoryCard,
    RevCardGrid,
    TempStaticCategory,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    categoriesFormatted() {
      return this.categories.map((category) => ({
        ...category,
        description: category.description || category.tagline || '',
        image: category.image || {},
        link: category.link || undefined,
        target: category.link?.targetBlank ? '_blank' : '_self',
      }))
    },

    hasSingleCard() {
      return this.categories.length === 1
    },

    singleCard() {
      return this.categories[0]
    },

    isSingleCardWithLinkVisible() {
      return this.hasSingleCard && !isEmpty(this.singleCard.link)
    },

    isSingleStaticCardVisible() {
      return this.hasSingleCard && isEmpty(this.singleCard.link)
    },

    isCardGridVisible() {
      return (
        !this.isSingleCardWithLinkVisible && !this.isSingleStaticCardVisible
      )
    },
  },

  methods: {
    sendTrackingData({ name }) {
      trackClick({
        ...this.tracking,
        name,
      })
    },
  },
}
