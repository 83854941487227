
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import isEmpty from 'lodash/isEmpty'

import { imageValidator } from '@cms/helpers/image'

export default {
  components: {
    RevCard,
    RevIllustration,
    RevIllustrationSource,
  },

  props: {
    description: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      validator: imageValidator,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasDescription() {
      return !isEmpty(this.description)
    },

    hasImageSrcMobile() {
      return !isEmpty(this.image?.srcMobile)
    },

    desktopWidth() {
      if (!this.image) {
        return 0
      }

      return this.image.width / 2
    },

    desktopHeight() {
      if (!this.image) {
        return 0
      }

      return this.image.height / 2
    },
  },
}
